import uuid from "react-uuid";

//Importing printing services image from printingService  in asset
import business from "../../assets/printingServices/business.webp";
import business1 from "../../assets/printingServices/business1.webp";
import business2 from "../../assets/printingServices/business2.webp";
import flyers from "../../assets/printingServices/flyer.webp";
import flyers1 from "../../assets/printingServices/flyer-1.webp";
import flyers2 from "../../assets/printingServices/flyer-2.webp";
import printingService from "../../assets/printingServices/printingServices.webp";
import brochure from "../../assets/printingServices/brochure.webp";
import brochure1 from "../../assets/printingServices/brochure-1.webp";
import brochure2 from "../../assets/printingServices/brochure-2.webp";
import poster from "../../assets/printingServices/poster.webp";
import poster1 from "../../assets/printingServices/poster-1.webp";
import poster2 from "../../assets/printingServices/poster-2.webp";
import catalouge from "../../assets/printingServices/catelouge.webp";
import catalouge1 from "../../assets/printingServices/catelouge-1.webp";
import catalouge2 from "../../assets/printingServices/catelouge-2.webp";
import menu from "../../assets/printingServices/menu.webp";
import menu1 from "../../assets/printingServices/menu-1.webp";
import menu2 from "../../assets/printingServices/menu-2.webp";

//Importing images from banner in asset
import banner from "../../assets/banners/banners.webp";
import vinyl from "../../assets/banners/vinyl.webp";
import vinyl1 from "../../assets/banners/vinyl1.webp";
import vinyl2 from "../../assets/banners/vinyl2.webp";
import mesh from "../../assets/banners/mesh.webp";
import mesh1 from "../../assets/banners/mesh1.webp";
import mesh2 from "../../assets/banners/mesh2.webp";
import rollup from "../../assets/banners/rollup.webp";
import rollup1 from "../../assets/banners/rollup1.webp";
import rollup2 from "../../assets/banners/rollup2.webp";
import pullUp from "../../assets/banners/pullUp.webp";
import pullUp1 from "../../assets/banners/pullUp1.webp";
import pullUp2 from "../../assets/banners/pullUp2.webp";
import fabric from "../../assets/banners/fabric.webp";
import fabric1 from "../../assets/banners/fabric1.webp";
import fabric2 from "../../assets/banners/fabric2.webp";
import adjustable from "../../assets/banners/adjustable.webp";
import adjustable1 from "../../assets/banners/adjustable1.webp";
import adjustable2 from "../../assets/banners/adjustable2.webp";
import bow from "../../assets/banners/bow.webp";
import bow1 from "../../assets/banners/bow1.webp";
import bow2 from "../../assets/banners/bow2.webp";

//Importing signage image from signage in asset
import signagePic from "../../assets/signage/signage.webp";
import aluminium from "../../assets/signage/aluminium.webp";
import aluminium1 from "../../assets/signage/aluminium1.webp";
import aluminium2 from "../../assets/signage/aluminium2.webp";
import parkSign from "../../assets/signage/parkSign.webp";
import parkSign1 from "../../assets/signage/parkSign1.webp";
import parkSign2 from "../../assets/signage/parkSign2.webp";
import bollardSign from "../../assets/signage/bollardSign1.webp";
import bollardSign1 from "../../assets/signage/bollardSign.webp";
import bollardSign2 from "../../assets/signage/bollardSign2.webp";
import foamBoard from "../../assets/signage/foamBoard.webp";
import foamBoard1 from "../../assets/signage/foamBoard1.webp";
import foamBoard2 from "../../assets/signage/foamBoard2.webp";
import corflute from "../../assets/signage/corflute.webp";
import corflute1 from "../../assets/signage/corflute1.webp";
import corflute2 from "../../assets/signage/corflute2.webp";
import asign from "../../assets/signage/asign.webp";
import asign1 from "../../assets/signage/asign1.webp";
import asign2 from "../../assets/signage/asign2.webp";

//Importing frames image from frames in asset
import frameImg from "../../assets/frames/frame.webp";
import signflate from "../../assets/frames/signflate.webp";
import signflate1 from "../../assets/frames/signflate1.webp";
import signflate2 from "../../assets/frames/signflate2.webp";
import realState from "../../assets/frames/realState.webp";
import realState1 from "../../assets/frames/realState1.webp";
import realState2 from "../../assets/frames/realState2.webp";

//Importing window stickers image from window in asset
import windowStickersImg from "../../assets/window/windowStickers.webp";
import oneWay from "../../assets/window/oneWay.webp";
import oneWay1 from "../../assets/window/oneWay1.webp";
import oneWay2 from "../../assets/window/oneWay2.webp";
import vinylW from "../../assets/window/vinylW.webp";
import vinylW1 from "../../assets/window/vinylW1.webp";
import vinylW2 from "../../assets/window/vinylW2.webp";
import frosting from "../../assets/window/frosting.webp";
import frosting1 from "../../assets/window/frosting1.webp";
import frosting2 from "../../assets/window/frosting2.webp";

//Social Icons
import { RiFacebookLine } from "react-icons/ri";
import fbIcon from "../../assets/fbIcon.webp";
import instaIcon from "../../assets/instaIcon.webp";

//Gallery Imports
import { BsFillTagsFill } from "react-icons/bs";

/* We have static data for all the nav link, products and it's images.*/
export const nav = [
  {
    text: "HOME",
    path: "/",
  },
  {
    text: "ABOUT",
    path: "/about",
  },
  {
    text: "PRODUCT",
    path: "/product",
  },

  {
    text: "CONTACT",
    path: "/contact",
  },
];

export const productions = [
  {
    id: uuid(),
    img: printingService,
    title: "Printing Services",
    text: `Welcome to Content AY Print and Sign, your one-stop-shop for all your printing needs. We are a professional printing company that offers a wide range of services to help promote your business and brand.We offer a variety of printing services that can help your business stand out from the competition. Some of our most popular services include: Business Cards, Flyers, Posters, Menus and many more..`,
    path: "/printingService",
  },
  {
    id: uuid(),
    img: banner,
    title: "Banners",
    text: " Our professional printing services are designed to help your business stand out from the crowd and make a lasting impression on your target audience.We offer a range of banner printing services that are perfect for promoting your business, event or product. Our most popular banner printing products includes: Vinyl, Pull Up, Teerdrop, Fabric Media Walls and many more..",
    path: "/banner",
  },
  {
    id: uuid(),
    img: signagePic,
    title: "Signage",
    text: "Here in  AY print &sign, we offer a wide range of banner printing services that are perfect for promoting your business, event, or product. From acrylic sign boards and corflute sign boards to aluminium composite panels, parking signs, bollard signs, and foam board signs, we have everything you need to make your brand shine.",
    path: "/signage",
  },
  {
    id: uuid(),
    img: frameImg,
    title: "Frames",
    text: "Our professional printing services are designed to help businesses of all sizes make a lasting impression on their target audience with high-quality, customized frames. Whether you're looking for a Signflate Inflatable frame to showcase your brand at outdoor events, or Real Estate frames to display property listings and promotions, we have the perfect solution for you.",
    path: "/frame",
  },
  {
    id: uuid(),
    img: windowStickersImg,
    title: "Window Stickers",
    text: "Welcome to Content AY Print and Sign, your go-to destination for all your window sticker printing needs. Our professional printing services are designed to help businesses of all sizes promote their brand, products, or services with high-quality, customized window stickers.We offer a wide range of window sticker products, including One Way Vision Stickers, Vinyl Window Stickers, and Frosting Film",
    path: "/window",
  },
];

export const finalProducts = [
  // Printing Services
  {
    id: "business",
    field: "printingServices",
    img: business2,
    img1: business,
    img2: business1,
    title: "Business Cards",
    text: "A great way to give yourself a professional look and make a lasting impression. Our business card printing services provide you with high-quality cards that will help you stand out from the competition. Our cards are printed on standard 350gsm stock and are available in a variety of shapes and sizes. We offer a full range of customization options, from full-color designs to embossing and foil stamping.",
    text2:
      "Whether you need 500 cards or 5,000 cards, our experienced team of professionals will ensure that your cards are printed quickly and accurately. So if you’re looking for a reliable business card printing service, look no further than us. ",
  },
  {
    id: "flyers",
    field: "printingServices",
    img: flyers,
    img1: flyers1,
    img2: flyers2,
    title: "Flyers",
    text: "With our flyers, you may create a magnificent array of marketing materials. We provide sizes in the following ranges: A3, A4, A5, A6, DL, and just to suit your fancy, bespoke sizing in between! Pick from a wide selection of 5 paper weights, ranging from light 128gsm paper to 300gsm paper for a little additional sturdiness, and then add either single-sided printing or double-sided printing to finish it off.",
    text2:
      "If your flyers are 200gsm or heavier, choose a gloss or matte paper treatment to complete them. Digital print in full colour and ultra-high resolution. A glossy, coated paper with excellent colour reproduction. Offered in A3, A4, A5, A6, or DL.",
  },
  {
    id: "brochure",
    field: "printingServices",
    img: brochure,
    img1: brochure1,
    img2: brochure2,
    title: "Brochures",
    text: "We offer high-quality brochure printing services, utilizing premium quality paper for a professional look and feel. Our experienced staff can assist you in selecting the best paper and guide you on how to best utilize our printing capabilities. We produce brochures in various sizes, paper weights, and finishes including glossy, matte, or other options.",
    text2:
      "We also offer design services to ensure your message stands out. Whether you need one or thousands of brochures, we pride ourselves on delivering a high-quality product you can be proud of.",
  },

  {
    id: "poster",
    field: "printingServices",
    img: poster,
    img1: poster1,
    img2: poster2,
    title: "Posters",
    text: "Our poster printing services offer a range of sizes, including A, AO, A1, A2, and A3, to meet your custom poster printing needs. We use the latest printing technology to ensure high-quality posters suitable for display in classrooms, offices, or any space. Our posters are printed on heavy-duty, premium-grade paper stock to make them long-lasting and highly durable.",
    text2:
      "We also provide various finishing options such as lamination, mounting, and framing to enhance your poster's overall look. With our quick turnaround time and competitive prices, you can trust us to provide the best posters printing services you need",
  },
  {
    id: "catalouge",
    field: "printingServices",
    img: catalouge,
    img1: catalouge1,
    img2: catalouge2,
    title: "Catalouges",
    text: "Printing catalogues is a great way to showcase your products or services in a visually appealing way. With our catalogues printing service, you can make sure your customers get an accurate representation of what you have to offer. Our catalogues are printed on high-quality paper stock and designed to your exact specifications. ",
    text2:
      "We work with experienced designers who will ensure that your catalogues look professional and attractive. Our catalogues printing service also includes a full range of finishing options such as gloss or matt lamination, embossing, foil stamping, and more. With our catalogues printing service, you can confidently present your products and services to your customers",
  },
  {
    id: "menu",
    field: "printingServices",
    img: menu,
    img1: menu1,
    img2: menu2,
    title: "Menu",
    text: "Our menu printing services offer a wide range of options to create high-quality menus quickly and easily. Whether you need a single menu for a special occasion or thousands for a chain of restaurants, we have you covered. We provide various paper stocks, finishes, and sizes to match your exact requirements.",
    text2:
      "Our menu printing services also offer customization options like full-color printing, die-cutting, lamination, folding, and more. Our team of professionals ensures that your menu is printed to the highest quality standards for a polished and professional look. We provide competitive pricing and quick turnaround times to get your menu in your hands as soon as possible. Get started now to give your restaurant a professional edge with our menu printing services.",
  },

  ///Banners
  {
    id: "vinyl",
    field: "banners",
    img: vinyl,
    img1: vinyl1,
    img2: vinyl2,
    title: "Vinyl Banners",
    text: "Our Vinyl Banners are perfect for adding a professional touch to any event or promotion. They're printed with high-quality materials and state-of-the-art equipment, ensuring your message stands out and gets the attention it deserves. These strong and durable banners can withstand all types of weather and light conditions for years to come.",
    text2:
      "With eye-catching full-colour graphics, our Vinyl Banners are ideal for both indoor and outdoor applications. They're perfect for trade shows, conventions, festivals, and other promotional events. Promote your business, message, or product in style with our Vinyl Banners and get noticed today.",
  },
  {
    id: "bow-banners",
    field: "banners",
    img: bow,
    img1: bow1,
    img2: bow2,
    title: "Bow Banners",
    text: "Our Bow Banners are an ideal way to draw attention and add a decorative touch to any special event or occasion. Crafted from high-quality materials, these banners feature a unique bow-shaped design, which is sure to stand out in any crowd. The fabric is lightweight and easy to store, so you can use it again and again.",
    text2:
      "The vibrant colors won't fade over time, meaning your message or logo will remain vibrant and eye-catching. With the included hardware, you'll have everything you need to hang your banner in no time. Whether you're decorating for a birthday party, wedding, or corporate event, our Bow Banners are the perfect choice for making a statement.",
  },

  {
    id: "rollup",
    field: "banners",
    img: rollup,
    img1: rollup1,
    img2: rollup2,
    title: "Pull Up Banners",
    text: "This Pull Up Banner is an ideal solution for any business, event, or promotion. It's perfect for displaying your logo, advertising message, or special event. The Pull Up Banner is designed to be easy to use and store. It is lightweight, so it can be moved and set up quickly. The banner is made of high-quality material that is durable and can withstand the elements. ",
    text2:
      "The banner is self-standing and easy to adjust to your desired height. It comes with a carrying case for easy transportation and storage. This Pull Up Banner is a great way to showcase your business brand or promote your special event",
  },
  {
    id: "mesh",
    field: "banners",
    img: mesh,
    img1: mesh1,
    img2: mesh2,
    title: "Mesh Banners",
    text: "Vinyl Mesh Banners are the perfect choice for any outdoor advertising. Our banners are made from heavy duty vinyl mesh, designed to be resistant to wind, rain and other outdoor elements. The vinyl mesh material ensures maximum visibility in windy conditions and provides excellent protection against fading and wear.",
    text2:
      "Our banners feature grommets along the edges for easy installation, ensuring that your message will be seen in any weather. The full color printing process ensures that your custom design will be brightly and clearly visible. Perfect for outdoor advertising, these banners are sure to make an impact and attract attention.",
  },
  {
    id: "pull-up",
    field: "banners",
    img: pullUp,
    img1: pullUp1,
    img2: pullUp2,
    title: "Teerdrop Banners",
    text: "Are you ready to make a statement and stand out from the crowd? Look no further than Content AY Print and Sign's pull up banner printing services! Our high-quality banners are not only durable and portable, but they're also sure to catch the eye of anyone passing by.",
  },
  {
    id: "fabric-banners",
    field: "banners",
    img: fabric,
    img1: fabric1,
    img2: fabric2,
    title: "Fabric Media Walls Banners",
    text: "Transform your event or space with Content AY Print and Sign's Fabric Media Walls Banners! Our custom-designed banners are the perfect way to showcase your brand, message, or artwork in a way that's sure to captivate and inspire. Our Fabric Media Walls Banners are made from high-quality fabric that is durable, lightweight, and easy to install, making them a perfect option for any event or space. ",
  },
  {
    id: "adjustable-banners",
    field: "banners",
    img: adjustable,
    img1: adjustable1,
    img2: adjustable2,
    title: "Adjustable Media Walls",
    text: "Looking for a versatile and attention-grabbing way to showcase your message or brand? Look no further than Content AY Print and Sign's Adjustable Media Walls! Our custom-designed walls are not only visually stunning, but they're also adjustable to fit any space or event.",
  },

  ///Signage
  {
    id: "acrylic-sign",
    field: "signage",
    img: asign1,
    img1: asign,
    img2: asign2,
    title: "Acrylic Sign Boards",
    text: "Our acrylic sign boards are made with durable and versatile materials, making them perfect for indoor advertising, office signage, directional signage, and more. Plus, our state-of-the-art printing technology ensures that your message or design is displayed with exceptional clarity and vibrancy, making a lasting impression on your audience.",
  },
  {
    id: "corflute",
    field: "signage",
    img: corflute,
    img1: corflute1,
    img2: corflute2,
    title: "Corflute Sign Boards",
    text: "At AY print & Sign, we offer a range of custom design options for your corflute signs, including different sizes, shapes, and finishes. Whether you're promoting a sale, a new product, or simply directing traffic to your event, we have the expertise and experience to help you create a sign that stands out and grabs attention.",
  },
  {
    id: "aluminium-panels",
    field: "signage",
    img: aluminium,
    img1: aluminium1,
    img2: aluminium2,
    title: "Aluminium Composite Panels Banners",
    text: "Take your advertising to new heights with Content AY Print and Sign's Aluminium Composite Panels Banners! Our custom-designed banners are not only visually stunning but also highly durable, making them the perfect option for outdoor advertising.",
  },
  {
    id: "park-sign",
    field: "signage",
    img: parkSign,
    img1: parkSign1,
    img2: parkSign2,
    title: "Parking Signs",
    text: "Looking for a way to clearly designate parking spaces or direct traffic in your parking lot? Look no further than Content AY Print and Sign's Parking Signs! Our custom-designed signs are not only functional but also visually striking, making them the perfect addition to any parking area.",
  },
  {
    id: "bollard-sign",
    field: "signage",
    img: bollardSign1,
    img1: bollardSign,
    img2: bollardSign2,
    title: "Bollard Signs",
    text: "Looking for a way to make a statement with your outdoor signage? Look no further than Content AY Print and Sign's Bollard Signs! Our custom-designed signs are not only functional but also visually stunning, making them the perfect addition to any outdoor space.",
  },
  {
    id: "foam-board",
    field: "signage",
    img: foamBoard,
    img1: foamBoard1,
    img2: foamBoard2,
    title: "Foam Board Signs",
    text: "Looking for a lightweight and cost-effective way to promote your brand or message? Look no further than Content AY Print and Sign's Foam Board Signs! Our custom-designed signs are not only affordable but also visually striking, making them the perfect addition to any indoor space.",
  },

  ///Frames
  {
    id: "signflate",
    field: "frames",
    img: signflate,
    img1: signflate1,
    img2: signflate2,
    title: "Signflate Instatable",
    text: "Our Signflate Inflatables are made from high-quality materials that are durable and weather-resistant, ensuring they'll stand up to the elements and last for years to come. And with our state-of-the-art printing technology, you can be confident that your message or brand will be displayed in the best possible way.",
  },
  {
    id: "real-state",
    field: "frames",
    img: realState,
    img1: realState1,
    img2: realState2,
    title: "Real State Frames",
    text: "Looking for a way to showcase your properties in a professional and eye-catching way? Look no further than Content AY Print and Sign's Real Estate Frames! Our custom-designed frames are the perfect solution for displaying your properties in a way that's both attractive and informative..",
  },

  //Window Stickers
  {
    id: "oneway",
    field: "window",
    img: oneWay,
    img1: oneWay1,
    img2: oneWay2,
    title: "One Way Vision Stickers",
    text: "Our one way vision stickers are made with specialized materials that allow for one-way visibility. This means that your message or design is visible from the outside, while still allowing those inside to see out. This makes them perfect for storefronts, offices, and vehicles.",
  },

  {
    id: "vinyl-window",
    field: "window",
    img: vinylW,
    img1: vinylW1,
    img2: vinylW2,
    title: "Vinyl Window Stickers",
    text: "Our Vinyl Window Stickers are made from high-quality materials that are both durable and long-lasting, ensuring that your message will be seen by countless people for years to come. And with our state-of-the-art printing technology, your graphics and text will be vibrant, crisp and clear.",
  },
  {
    id: "frosting",
    field: "window",
    img: frosting,
    img1: frosting1,
    img2: frosting2,
    title: "Frosting film",
    text: "Transform your brand with Frosting - the mouth-watering film that will leave you craving more. At AY print & sign, we understand the importance of creating a visual identity that reflects the sweetness of your brand, and our expert printing and signage services can help you achieve just that.",
  },
];

export const socialIcons = [
  {
    icon: fbIcon,

    color: "text-fbIcon",

    href: "/",
  },
  {
    icon: instaIcon,
    color: "text-instaIcon",
    href: "/",
  },
];
