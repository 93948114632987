import Footer from "../components/footer/Footer";
import Header from "../components/Header";
import { Link, useParams } from "react-router-dom";
import { finalProducts } from "../components/data/Data";
import ProductNotFound from "../components/common/products/ProductNotFound";

const ProductRoute = () => {
  let params = useParams();

  const product = finalProducts.filter((data) => data.id === params.id);

  return (
    <>
      <Header />
      <div className="py-6 md:mt-12">
        {!product.length > 0 && <ProductNotFound />}
        {product.map((list, index) => (
          <div
            key={index}
            className="flex flex-col md:flex-row-reverse  px-12 gap-x-24"
          >
            {/* <div className="flex md:flex-col sm:flex-row flex-col pt-6 md:pt-auto space-y-3">
              <span className="">
                <img
                  src={list?.img}
                  alt=""
                  className="w-screen h-72 shadow-2xl shadow-black"
                />
              </span>
              <span className="flex flex-row  space-x-2 ">
                <img
                  src={list?.img1}
                  alt=""
                  className="w-6/12  shadow-2xl shadow-black"
                />
                <img
                  src={list?.img2}
                  alt=""
                  className="w-6/12 shadow-2xl shadow-black"
                />
              </span>
            </div> */}
            <div className=" w-fit grid grid-flow-row space-y-4 ">
              <span className="grid grid-cols-1 ">
                <img
                  src={list.img}
                  alt=""
                  className="h-40 md:h-60 xl:h-72  w-full shadow-2xl shadow-black"
                />
              </span>
              <span className=" grid grid-cols-2 space-x-4 px-2 mr-2 ">
                <img
                  src={list.img1 || list.img}
                  alt=""
                  className=" w-full h-full object-cover shadow-2xl shadow-black"
                />
                <img
                  src={list.img2 || list.img}
                  alt="w-fit"
                  className="w-full h-full object-cover shadow-2xl shadow-black"
                />
              </span>
            </div>
            <div className="basis-11/12">
              <h2 className="font-extrabold text-2xl md:text-3xl xl:text-5xl m-6 ">
                {list.title}
              </h2>

              <p className="text-lg md:text-xl">{list.text}</p>
              <p className="text-lg md:text-xl mt-3">{list.text2}</p>
              <p className="py-4 text-gray text-lg">
                Place your order online by sending us an enquiry or call us on
                <a href="tel:+61401122107" className="font-bold m-1">
                  0401122107,
                </a>
                Please send us your enquiry and we will respond to you within 24
                hours.
              </p>
            </div>
          </div>
        ))}

        <div className="flex items-center md:mx-24 px-4 md:px-12 xl:px-329 justify-center mt-6 ">
          <Link to="/contact">
            <button className="font-bold text-sm md:text-xl  px-4 py-2 mb-12 bg-subText  text-headerText ">
              Request Quote
            </button>
          </Link>
          <a
            to="/contact"
            target="_blank"
            rel="noreferrer"
            href="https://www.canva.com/"
          >
            <button className="font-bold text-sm md:text-xl px-4 py-2 mb-12  bg-bannerText text-headerText ">
              Design Online
            </button>
          </a>
        </div>
      </div>

      <Footer />
    </>
  );
};
export default ProductRoute;
