import Search from "../search/Search";
import SearchResult from "../search/SearchResult";

const ProdHeader = () => {
  return (
    <>
      <h1 className=" text-4xl text-subText mb-6 p-8 underline underline-offset-8 decoration-bannerText ">
        Our Products
      </h1>
      <div className="mb-12">
        <Search length="w-4/6" style="px-6 md:px-32" color="banner-text" />
      </div>
      <SearchResult />
    </>
  );
};
export default ProdHeader;
