import { Link } from "react-router-dom";

import board1 from "../../assets/galleries/board.jpg";
import win from "../../assets/galleries/win.jpg";
import tbone from "../../assets/galleries/tbone.jpg";
import "./gallery.css";

const Gallery = () => {
  const images = [board1, tbone, win];
  return (
    <>
      <section className="gallery md:px-18 py-4 md:py-12 mx-2 md:mx-6 my-3  bg-bgProject border-2 md:border-2 rounded-md border-text ">
        <h1 className=" font-semibold md:text-3xl pl-6 md:pl-12 text-base pb-2">
          FEW OF OUR RECENT WORKS
        </h1>
        <div
          className={`collage md:ml-12  grid grid-rows-1 grid-cols-1 md:grid-cols-3 px-6  py-6 gap-6 md:gap-12 w-full   md:-mr-0
           `}
        >
          {images.map((img, index) => (
            <Link
              to="/gallery"
              key={index}
              className="ml-5 md:ml-0 -mr-8 md:-mr-auto"
            >
              <img
                src={img}
                alt=""
                className={`
                  ${index === 2 ? "hidden md:block" : ""}
                  w-full h-full transition duration-1000 transform hover:scale-110 `}
              />
            </Link>
          ))}
        </div>
        <button className="block mx-auto bg-subText  md:py-2 rounded-lg  ">
          <Link
            to="/gallery"
            className="text-headerText font-semibold text-sm md:text-2xl px-3 -mt-4"
          >
            VIEW OUR GALLERY
          </Link>
        </button>
      </section>
    </>
  );
};

export default Gallery;
