import { useState } from "react";
import { Link } from "react-router-dom";
import { finalProducts } from "../../data/Data";
import Allproducts from "./AllProducts";

const ProductHover = () => {
  const [display, setDisplay] = useState(true);
  return (
    <>
      <section
        onMouseEnter={() => setDisplay(true)}
        onMouseLeave={() => setDisplay(false)}
      >
        {display && <Allproducts />}
      </section>
    </>
  );
};
export default ProductHover;
