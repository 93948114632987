import Query from "../components/contact/Query";
import Footer from "../components/footer/Footer";
import Header from "../components/Header";
import { ProductState } from "../context/Context";
const QueryRoute = () => {
  const {
    state: { isAuthenticated },
  } = ProductState();
  return (
    <>
      {isAuthenticated && (
        <>
          <Header />
          <h1 className="text-xl text-center pt-12 font-bold pb-12 text-subText">
            Your Customer Query are as follows:
          </h1>
          <Query />
          <Footer />
        </>
      )}
    </>
  );
};
export default QueryRoute;
