import { useContext } from "react";
import { createContext, useReducer } from "react";
import { productions, finalProducts } from "../components/data/Data.js";
import { productReducer } from "./Reducer.js";

const Product = createContext();

const Context = ({ children }) => {
  const [state, dispatch] = useReducer(productReducer, {
    product: productions,

    sortedProduct: productions,
    finalProduct: finalProducts,
    sortedFinalProduct: finalProducts,
    isAuthenticated: false,

    searchQuery: "",
  });
  if (state.searchQuery == "") {
    state.sortedProduct = productions;
    state.sortedFinalProduct = finalProducts;
  } else {
    state.sortedProduct = state.product.filter((prod) =>
      prod.title.toLowerCase().includes(state.searchQuery)
    );
    state.sortedFinalProduct = state.finalProduct.filter((prod) =>
      prod.title.toLowerCase().includes(state.searchQuery)
    );
  }
  return (
    <Product.Provider value={{ state, dispatch }}>{children}</Product.Provider>
  );
};

export default Context;

export const ProductState = () => {
  return useContext(Product);
};
