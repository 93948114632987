import CommonProducts from "../components/common/products/CommonProducts";
import ProdHeader from "../components/common/products/ProdHeader";
import Search from "../components/common/search/Search";
import Footer from "../components/footer/Footer";
import Header from "../components/Header";
import { ProductState } from "../context/Context";
const PrintingServiceRoute = () => {
  const {
    state: { sortedFinalProduct },
  } = ProductState();
  return (
    <>
      <Header />
      <ProdHeader />

      <CommonProducts
        product={sortedFinalProduct.filter(
          (product) => product.field === "printingServices"
        )}
      />
      <Footer />
    </>
  );
};
export default PrintingServiceRoute;
