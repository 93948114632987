// import { gallery } from "../../../backend/${blog.image}";
import blog from "../../assets/galleries/blog.webp";
import testi from "../../assets/galleries/testimony.webp";
import { BsStarFill, BsFillTagsFill } from "react-icons/bs";

import { Link } from "react-router-dom";

import { useEffect, useState } from "react";
import axios from "axios";
import Loading from "../Loading";
const GalleryPage = () => {
  const [fetchedBlogs, setFetchBlogs] = useState([]);

  const tags = <BsFillTagsFill />;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://ayprintsign.onrender.com/blogs"
        ); //call the getBlogs function to fetch the blogs
        setFetchBlogs(response.data.data);

        //update the state with the fetched blogs
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const images = fetchedBlogs.map((data) => data.image);
  images.map((image) => (
    <div>
      <p>{image}</p>
    </div>
  ));

  return (
    <>
      <section>
        <div className=" md:w-123 pt-2 h-full md:h-111 px-4  md:pt-auto md:py-4 md:px-auto ">
          <img
            src={blog}
            alt=""
            className=" inset-0 w-full h-full object-contain"
          />
        </div>
        {fetchedBlogs.length >= 1 ? (
          <>
            {fetchedBlogs?.map((gallery, index) => {
              return (
                <div className="w-11/12 m-auto ">
                  <div
                    className={`${
                      index % 2 !== 0 && `lg:flex-row-reverse `
                    } flex  lg:flex-row flex-col md:space-x-24 md:py-4 py-6 px-1  justify-around`}
                  >
                    <div className="sm:w-122 lg:w-121  md:py-6 ">
                      <div className="w-fit grid grid-flow-row gap-4">
                        <div className="grid grid-cols-1">
                          <img
                            src={
                              gallery?.image[1] &&
                              typeof gallery?.image[1] === "object" && // check if image is in octet-stream format
                              gallery?.image[1].type ===
                                "application/octet-stream"
                                ? URL.createObjectURL(gallery?.image[1]) // create blob URL from image data
                                : gallery?.image[1] // otherwise use the image URL
                            }
                            alt=""
                            className="w-full object-cover shadow-2xl shadow-black"
                            style={{ maxHeight: "90%" }}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="grid grid-cols-1">
                            <img
                              src={
                                gallery?.image[0] &&
                                typeof gallery?.image[0] === "object" && // check if image is in octet-stream format
                                gallery?.image[0].type ===
                                  "application/octet-stream"
                                  ? URL.createObjectURL(gallery?.image[0]) // create blob URL from image data
                                  : gallery?.image[0] // otherwise use the image URL
                              }
                              alt=""
                              className="w-full object-cover shadow-2xl shadow-black"
                              style={{ maxHeight: "50%" }}
                            />
                          </div>
                          <div className="grid grid-cols-1">
                            <img
                              src={
                                gallery?.image[2] &&
                                typeof gallery?.image[2] === "object" && // check if image is in octet-stream format
                                gallery?.image[2].type ===
                                  "application/octet-stream"
                                  ? URL.createObjectURL(gallery?.image[2]) // create blob URL from image data
                                  : gallery?.image[2] // otherwise use the image URL
                              }
                              alt=""
                              className="w-full object-cover shadow-2xl shadow-black"
                              style={{ maxHeight: "50%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="lg:pr-32">
                      <span className="text-2xl md:text-3xl py-2 font-bold text-textOffer">
                        {gallery?.title}
                      </span>
                      <div className="flex flex-row px-4 w-fit ">
                        <span className="text-xl md:text-2xl py-4">{tags}</span>
                        <span className="pr-0 md:pr-auto">
                          {gallery?.tags.map((tags) => (
                            <span className="p-2 md:p-2 text-sm font-bold md:text-xl">
                              <Link
                                to="/banner"
                                className="hover:text-bannerText"
                              >
                                {tags}
                              </Link>
                            </span>
                          ))}
                        </span>
                      </div>
                      <p className="text-lg py-4">{gallery?.description}</p>
                      <div className="flex flex-row space-x-12 w-fit">
                        <div className="testimonial  overflow-clip">
                          <img
                            src={testi}
                            alt=""
                            className=" rounded-full  border-2 border-textOffer"
                          />
                        </div>
                        <div>
                          <span className="font-bold text-sm md:text-lg">
                            {gallery?.reviewer}
                          </span>

                          <span className="flex flex-row w-1/4 py-2">
                            {gallery?.review &&
                              Number.isInteger(parseInt(gallery?.review)) &&
                              Array(parseInt(gallery?.review))
                                .fill(<BsStarFill />)
                                .map((star) => (
                                  <span className=" text-lg p-1 md:text-2xl text-subText">
                                    {star}
                                  </span>
                                ))}
                          </span>

                          <div className="md:w-10/12">
                            <span>“ {gallery?.testimonial} ”</span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <Loading />
        )}
      </section>
    </>
  );
};
export default GalleryPage;
