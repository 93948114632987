import ProdHeader from "../components/common/products/ProdHeader";
import Products from "../components/common/products/Products";
import Footer from "../components/footer/Footer";
import Header from "../components/Header";

const ProductRoute = () => {
  return (
    <>
      <Header />
      <ProdHeader />
      <Products />
      <Footer />
    </>
  );
};
export default ProductRoute;
