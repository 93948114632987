import React from "react";
import { Route, Naviagte, Outlet } from "react-router-dom";
import { ProductState } from "../context/Context";

const ProtectedRoute = () => {
  const {
    state: { isAuthenticated },
  } = ProductState();

  return isAuthenticated && <Outlet />;
};

export default ProtectedRoute;
