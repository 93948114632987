import Footer from "../components/footer/Footer";
import BlogForm from "../components/gallery/BlogForm";
import Header from "../components/Header";
const BlogRoute = () => {
  return (
    <>
      <Header />
      <BlogForm />
      <Footer />
    </>
  );
};
export default BlogRoute;
