import { Link } from "react-router-dom";
import about from "../../assets/about.webp";
import mission from "../../assets/mission.webp";

const About = () => {
  return (
    <>
      <section>
        <h1 className="font-extrabold text-2xl md:text-5xl text-center text-subText p-8 mb-6">
          About AY Print & Sign
        </h1>

        <div className="first flex flex-col-reverse md:flex-row content-between  p-4 md:p-8">
          <div className=" w-full md:w-6/7  ">
            <p className="pb-4 md:pb-12 p-4 px-1  text-sm lg:text-base md:text-auto ">
              Welcome to AY Print &amp; Sign! We are a local print and sign
              shop, established in 2023 with the goal of providing the highest
              quality printing and signage services for local businesses. Our
              experienced team is passionate about delivering exceptional
              customer service and quality results. We are the experts in
              creating a new look for businesses, providing signage, light
              boxes, window stickers, neon lights, and more. Here we also
              provide a various range of products such as business cards,
              flyers, brochures, menus, sandwich boards, pull up banners, vinyl
              banners, custom stickers, labels, media walls, mesh banners,
              frosting films and many more.
            </p>
            <p
              className="pb-4 p-4 px-1 text-sm lg:text-base md:text-auto
            "
            >
              At AY Print &amp; Sign, we strive to deliver the highest quality
              products at a reasonable cost. We understand that cost is an
              important factor for our customers and we are dedicated to
              ensuring that our rates are competitive. Our experienced
              installers are highly trained in their respective fields and can
              handle any installation needs. We provide services to a variety of
              industries including restaurants, cafes, retail stores, takeaways,
              real estate, building &amp; constructions, hotels and more. Our
              goal is to provide our customers with the highest quality printing
              and signage services possible, at reasonable prices. We are
              committed to ensuring that each customer is satisfied with the
              results and the overall experience. If you are looking for a
              reliable and experienced printing and signage shop, look no
              further than AY Print &amp; Sign.{" "}
              <Link to="/contact" className="font-bold text-bannerText mr-1">
                Contact
              </Link>
              us today to learn more about our services and how we can help you
              to transform your business.
            </p>
          </div>
          <div className="px-6">
            <img src={about} alt="" className=" h-auto md:h-96  md:px-auto " />
          </div>
        </div>

        <div className="flex flex-col md:flex-row">
          <div className="">
            <img src={mission} alt="" className="w-full" />
          </div>
          <div className="w-full md:w-2/3">
            <span className="p-4">
              <h1 className="font-bold text-2xl text-bannerText  p-2 text-center">
                Our Mission
              </h1>
              <p
                className="pb-4
                p-4 text-sm lg:text-base 
            "
              >
                Our mission at AY Print &amp; Sign is to provide excellent
                printing and signage services to our customers, utilizing only
                the highest quality methods and materials in order to exceed
                their expectations. We strive to provide superior customer
                service and create an environment where our customers feel
                valued and respected. We are committed to creating excellent
                products to meet our customers’ needs and helping them promote
                their business or brand.
              </p>
            </span>
            <span className="p-4">
              <h1 className="font-bold text-2xl text-bannerText  pb-4 text-center">
                Our Vision
              </h1>
              <p className="pb-4 p-4 text-sm lg:text-base ">
                Our vision at AY Print &amp; Sign is to provide high-quality,
                cost-effective printing and signage solutions that exceed
                customer expectations and contribute to the growth and success
                of our clients. We strive to be a trusted partner in the
                industry by delivering innovative and reliable services that
                reflect our commitment to excellence.
              </p>
            </span>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
