import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import Context from "./context/Context";

const root = ReactDOM.createRoot(document.getElementById("root"));
const scrollRestoration = () => {
  window.scrollTo(0, 0);
};
root.render(
  <React.StrictMode>
    <BrowserRouter
      onUpdate={scrollRestoration}
      onBeforeUnload={scrollRestoration}
    >
      <Context>
        <App />
      </Context>
    </BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals())
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
