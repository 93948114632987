import Footer from "../components/footer/Footer";
import GalleryPage from "../components/gallery/GalleryPage";
import Header from "../components/Header";
const GalleryRoute = () => {
  return (
    <>
      <Header />
      <GalleryPage />
      <Footer />
    </>
  );
};
export default GalleryRoute;
