// import { Link, useNavigate } from "react-router-dom";
// import { ProductState } from "../../context/Context";

// const Landing = () => {
//   const {
//     state: { isAuthenticated },
//   } = ProductState();

//   (isAuthenticated);
//   return (
//     <>
//       <h1 className="text-center text-xl font-extrabold text-subText pb-6">
//         Welcome to AY Print & Sign, Admin section, Please choose from following:
//       </h1>
//       <div className="flex flex-row items-center justify-around  bg-gray-100 w-1/2 mx-auto py-4 px-4">
//         <Link
//           to="/blog"
//           className="px-4 py-2 bg-green-500 text-white rounded-md shadow-md mb-4"
//         >
//           Add a blog
//         </Link>
//         <Link
//           to="/query"
//           className="px-4 py-2 bg-green-500 text-white rounded-md shadow-md"
//         >
//           See Customers Query
//         </Link>
//       </div>
//     </>
//   );
// };
// export default Landing;
import { Link, useNavigate } from "react-router-dom";
import { ProductState } from "../../context/Context";
import Loading from "../Loading";
import "./styles.css";

const Landing = () => {
  const {
    state: { isAuthenticated },
  } = ProductState();

  return (
    <>
      <div className="bg-bubbles">
        <div className="bubble bubble1"></div>
        <div className="bubble bubble2"></div>
        <div className="bubble bubble3"></div>
      </div>

      <h1 className="text-center text-xl font-extrabold text-subText pb-6">
        Welcome to AY Print & Sign, Admin section, Please choose from following:
      </h1>
      <div className="flex flex-col md:flex-row items-center justify-around  bg-gray-100 w-10/12 md:w-1/2 mx-auto py-4 px-4">
        <Link
          to="/blog"
          className="px-4 py-2 bg-green-500 text-white rounded-md shadow-md mb-4"
        >
          Add a blog
        </Link>
        <Link
          to="/query"
          className="px-4 py-2 bg-green-500 text-white rounded-md shadow-md"
        >
          See Customers Query
        </Link>
      </div>
    </>
  );
};
export default Landing;
