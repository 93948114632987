// import { gallery } from "../../../backend/${blog.image}";
import blog from "../../assets/galleries/blog.webp";
import testi from "../../assets/galleries/testimony.webp";
import { BsStarFill, BsFillTagsFill } from "react-icons/bs";

import { Link } from "react-router-dom";

import { useEffect, useState } from "react";

import axios from "axios";
import Loading from "../Loading";
const UpdateGallery = () => {
  const [fetchedBlogs, setFetchBlogs] = useState([]);
  const [formData, setFormData] = useState({
    title: "",
    tags: "",
    description: "",
    testimonial: "",
    reviewer: "",
    image: null,
    review: null,
  });

  const tags = <BsFillTagsFill />;

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await axios.get(
          "https://ayprintsign.onrender.com/blogs/update"
        ); //call the getBlogs function to fetch the blogs
        setFetchBlogs(response.data.data);

        //update the state with the fetched blogs
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, []);
  const images = fetchedBlogs.map((data) => data.image);
  images.map((image) => (
    <div>
      <p>{image}</p>
    </div>
  ));

  const handleTestimonialChange = (event) => {
    setFormData({ ...formData, testimonial: event.target.value });
  };

  const handleReviewerChange = (event) => {
    setFormData({ ...formData, reviewer: event.target.value });
  };

  const handleReviewChange = (event) => {
    setFormData({ ...formData, review: event.target.value });
  };
  // const _id = fetchedBlogs.map((blogs) => blogs._id);
  const handleSubmit = async (event, _id) => {
    event.preventDefault(); // prevent default form submission behavior

    const formPayload = new FormData(); // create a new FormData object to hold the form data

    // add each field to the FormData object
    formPayload.append("testimonial", formData.testimonial);
    formPayload.append("reviewer", formData.reviewer);
    formPayload.append("review", formData.review);
    const testimonial = formData.testimonial;
    const reviewer = formData.reviewer;
    const review = formData.review;
    // send an HTTP POST request to the server with the form data

    try {
      const response = await axios.patch(
        `https://ayprintsign.onrender.com/blogs/${_id}`,
        {
          testimonial,
          reviewer,
          review,
        }
      );

      setFormData({
        title: "",
        tags: "",
        description: "",
        testimonial: "",
        reviewer: "",
        image: null,
        review: null,
      });
      // handle success
    } catch (error) {
      // handle error
      console.error(error);
    }
  };

  const inputStyles =
    "rounded-md border-gray-300 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 py-2 px-3 w-full ";
  const textAreaStyles =
    "rounded-md border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 py-2 px-3 w-full resize-none";
  const labelText = "block text-gray-700 font-bold mb-2";
  const options = Array.from({ length: 5 }, (_, i) => i + 1);
  return (
    <>
      <section>
        {fetchedBlogs.length >= 1 ? (
          <>
            {fetchedBlogs?.map((gallery, index) => {
              return (
                <div className="w-9/12 m-auto md:py-8 ">
                  <div
                    className={`${
                      index % 2 !== 0 && `lg:flex-row-reverse `
                    } flex  lg:flex-row flex-col md:space-x-24 md:py-4 py-6 px-1`}
                  >
                    <div className="sm:w-122 lg:w-121  md:py-6 ">
                      <div className="w-fit grid grid-flow-row gap-4">
                        <div className="grid grid-cols-1">
                          <img
                            src={
                              gallery?.image[1] &&
                              typeof gallery?.image[1] === "object" && // check if image is in octet-stream format
                              gallery?.image[1].type ===
                                "application/octet-stream"
                                ? URL.createObjectURL(gallery?.image[1]) // create blob URL from image data
                                : gallery?.image[1] // otherwise use the image URL
                            }
                            alt=""
                            className="w-full object-cover shadow-2xl shadow-black"
                            style={{ maxHeight: "90%" }}
                          />
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="grid grid-cols-1">
                            <img
                              src={
                                gallery?.image[0] &&
                                typeof gallery?.image[0] === "object" && // check if image is in octet-stream format
                                gallery?.image[0].type ===
                                  "application/octet-stream"
                                  ? URL.createObjectURL(gallery?.image[0]) // create blob URL from image data
                                  : gallery?.image[0] // otherwise use the image URL
                              }
                              alt=""
                              className="w-full object-cover shadow-2xl shadow-black"
                              style={{ maxHeight: "50%" }}
                            />
                          </div>
                          <div className="grid grid-cols-1">
                            <img
                              src={
                                gallery?.image[2] &&
                                typeof gallery?.image[2] === "object" && // check if image is in octet-stream format
                                gallery?.image[2].type ===
                                  "application/octet-stream"
                                  ? URL.createObjectURL(gallery?.image[2]) // create blob URL from image data
                                  : gallery?.image[2] // otherwise use the image URL
                              }
                              alt=""
                              className="w-full object-cover shadow-2xl shadow-black"
                              style={{ maxHeight: "50%" }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <span className="text-2xl md:text-3xl py-2 font-bold text-textOffer">
                        {gallery?.title}
                      </span>
                      <div className="flex flex-row px-4 w-fit ">
                        <span className="text-xl md:text-2xl py-4">{tags}</span>
                        <span className="pr-0 md:pr-auto">
                          {gallery?.tags.map((tags) => (
                            <span className="p-2 md:p-2 text-sm font-bold md:text-xl">
                              <Link
                                to="/banner"
                                className="hover:text-bannerText"
                              >
                                {tags}
                              </Link>
                            </span>
                          ))}
                        </span>
                      </div>
                      <p className="text-lg py-4">{gallery?.description}</p>
                      <div className="flex flex-row space-x-12 w-fit">
                        <div className="testimonial  w-112 md:w-114  xxl:w-111 overflow-clip">
                          <img
                            src={testi}
                            alt=""
                            className=" rounded-full  border-2 border-textOffer"
                          />
                        </div>
                        <form
                          onSubmit={(e) => handleSubmit(e, gallery._id)}
                          className="space-y-4 p-4 pl-8 bg-gray-100 border border-gray-200 rounded-md shadow-md transition duration-150 w-10/12 mx-6"
                        >
                          <div>
                            <label className={labelText} htmlFor="testimonial">
                              Testimonial:
                            </label>
                            <textarea
                              id="testimonial"
                              value={formData.testimonial}
                              onChange={handleTestimonialChange}
                              required
                              className={textAreaStyles}
                            />
                          </div>
                          <div>
                            <label className={labelText} htmlFor="reviewer">
                              Reviewer:
                            </label>
                            <input
                              type="text"
                              id="reviewer"
                              value={formData.reviewer}
                              onChange={handleReviewerChange}
                              className={`rounded-md border-gray-300 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 py-2 px-3 w-10/12`}
                            />
                          </div>
                          <div>
                            <label className={labelText} htmlFor="review">
                              Review Stars:
                            </label>
                            <select
                              id="review"
                              value={formData.review}
                              onChange={handleReviewChange}
                              className="w-32 py-2"
                            >
                              {options.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                              ))}
                            </select>
                          </div>
                          <button
                            type="submit"
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
                          >
                            Submit
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </>
        ) : (
          <Loading />
        )}
      </section>
    </>
  );
};
export default UpdateGallery;
