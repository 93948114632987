import "./App.css";
import { Routes, Route } from "react-router-dom";
import Home from "./routes/Home";
import ProductRoute from "./routes/ProductRoute";
import ContactRoute from "./routes/ContactRoute";
import AboutRoute from "./routes/AboutRoute";
import SingleProductRoute from "./routes/SingleProductRoute";

import PrintingServiceRoute from "./routes/PrintingServiceRoute";
import BannerRoute from "./routes/BannerRoute";
import FrameRoute from "./routes/FrameRoute";
import SignageRoute from "./routes/SignageRoute";
import WindowStickerRoute from "./routes/WindowStickerRoute";
import GalleryRoute from "./routes/GalleryRoute";
import BlogRoute from "./routes/BlogRoute";
import LoginPage from "./components/admin/LoginPage";
import ProtectedRoute from "./routes/ProtectedRoute";
import Query from "./components/contact/Query";
import QueryRoute from "./routes/QueryRoute";
import Landing from "./components/admin/Landing";
import { useEffect, useRef } from "react";
import UpdateGalleryRoute from "./routes/UpdateGalleryRoute";

function App() {
  // const prevScrollY = useRef(0);

  // useEffect(() => {
  //   return () => {
  //     prevScrollY.current = window.scrollY;
  //   };
  // }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactRoute />} />
        <Route path="/about" element={<AboutRoute />} />
        <Route path="/product" element={<ProductRoute />} />
        <Route path="/printingService" element={<PrintingServiceRoute />} />
        <Route path="/banner" element={<BannerRoute />} />
        <Route path="/frame" element={<FrameRoute />} />
        <Route path="/signage" element={<SignageRoute />} />
        <Route path="/window" element={<WindowStickerRoute />} />
        <Route path="/gallery" element={<GalleryRoute />} />
        <Route path="/update" element={<UpdateGalleryRoute />} />

        <Route path="/login" element={<LoginPage />} />

        <Route path="/query" element={<QueryRoute />} />
        <Route element={<ProtectedRoute />}>
          <Route path="/landing" element={<Landing />} />
          <Route path="/blog" element={<BlogRoute />} />
          <Route path="/query" element={<QueryRoute />} />

          {/* <ProtectedRoute path="/blog" component={BlogRoute} /> */}
        </Route>

        <Route path="/product/:id" element={<SingleProductRoute />} />
      </Routes>
    </>
  );
}

export default App;
