import { useState } from "react";
import { Link } from "react-router-dom";
import { finalProducts } from "../../data/Data";
const AllProducts = () => {
  return (
    <div className="main grid grid-cols-3 md:grid-cols-5 px-1 md:px-12 space-y-4 md:space-y-0 space-x-6 bg-productHover">
      {/* Printing Service */}
      <div className="mt-3 md:mt-0">
        <Link>
          <h1 className="text-lg font-extrabold">Printing Service</h1>
        </Link>
        <hr className="w-5/6 py-0.5 bg-black" />
        <ul>
          {finalProducts
            .filter((product) => product.field === "printingServices")
            .map((printing, index) => (
              <li key={index} className="text-xs md:text-sm py-1">
                <Link
                  to={`/product/${printing.id}`}
                  className="hover:text-bannerText"
                >
                  {printing.title}
                </Link>
              </li>
            ))}
        </ul>
      </div>

      {/* Banners */}
      <div className="">
        <Link>
          <h1 className="text-lg font-extrabold">Banners</h1>
        </Link>
        <hr className="w-4/6 py-0.5 bg-black" />
        <ul>
          {finalProducts
            .filter((product) => product.field === "banners")
            .map((banner, index) => (
              <li key={index} className="text-xs md:text-sm py-1">
                <Link
                  to={`/product/${banner.id}`}
                  className="hover:text-bannerText"
                >
                  {banner.title}
                </Link>
              </li>
            ))}
        </ul>
      </div>

      {/* Signage */}
      <div>
        <Link>
          <h1 className="text-lg font-extrabold">Signage</h1>
        </Link>
        <hr className="w-4/6 py-0.5 bg-black" />
        <ul>
          {finalProducts
            .filter((product) => product.field === "signage")
            .map((signage, index) => (
              <li key={index} className="text-xs md:text-sm py-1">
                <Link
                  to={`/product/${signage.id}`}
                  className="hover:text-bannerText"
                >
                  {signage.title}
                </Link>
              </li>
            ))}
        </ul>
      </div>

      {/* Window Stickers */}
      <div>
        <Link>
          <h1 className="text-lg font-extrabold ">Window Stickers</h1>
        </Link>
        <hr className="w-4/6 py-0.5 bg-black" />
        <ul>
          {finalProducts
            .filter((product) => product.field === "window")
            .map((window, index) => (
              <li key={index} className="text-xs md:text-sm py-1">
                <Link
                  to={`/product/${window.id}`}
                  className="hover:text-bannerText"
                >
                  {window.title}
                </Link>
              </li>
            ))}
        </ul>
      </div>

      {/* Frames */}
      <div>
        <Link>
          <h1 className="text-lg font-extrabold">Frames</h1>
        </Link>
        <hr className="w-4/6 py-0.5 bg-black" />
        <ul>
          {finalProducts
            .filter((product) => product.field === "frames")
            .map((frames, index) => (
              <li key={index} className="text-xs md:text-sm py-1">
                <Link
                  to={`/product/${frames.id}`}
                  className="hover:text-bannerText"
                >
                  {frames.title}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};
export default AllProducts;
