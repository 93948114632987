import React from "react";
import { useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

import { ProductState } from "../../context/Context";
import { useNavigate } from "react-router-dom";

function LoginPage() {
  const navigate = useNavigate();
  const [showAlert, setShowAlert] = useState("");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const {
    state: { isAuthenticated },
    dispatch,
  } = ProductState();

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await axios.post(
        "https://ayprintsign.onrender.com/login/auth",
        {
          username,
          password,
        }
      );
      dispatch({ type: "USER_AUTHENTICATED" });
      // if (response.data.success) {
      //   isAuthenticated = true;
      // }
      setPassword("");
      setUsername("");

      navigate("/landing");
    } catch (error) {
      console.error(error);
      const errorMsg = await error.response.data.msg;
      setShowAlert(errorMsg);
      await error.response.data.msg;
    }
  };

  if (showAlert) {
    setTimeout(() => {
      setShowAlert("");
    }, 2000);
  }
  return (
    <>
      <div className="container absolute top-12 md:top-6 pt-12 left-12 md:left-24">
        <div className="text-center ">
          <h1 className="text-2xl md:text-4xl text-subText font-bold mb-2 md:p-4 md:pb-6">
            Admin Login Page
          </h1>
          {showAlert && (
            <p className="text-red-800 font-bold pb-6">{showAlert}</p>
          )}
        </div>
        <form
          className="bg-white shadow-md rounded px-8 pt-6 pb-8 mb-4 md:w-8/12 mx-auto"
          onSubmit={handleSubmit}
        >
          <div className="mb-4 p-2">
            <label
              className="block text-gray-700  md:text-2xl font-bold mb-2"
              htmlFor="username"
            >
              Username
            </label>
            <input
              className="shadow appearance-none border rounded font-bold text-sm md:text-base w-full py-3 md:py-5 px-2 md:px-8 text-gray-700 leading-tight focus:outline-none focus:shadow-outline hover:bg-gray-100"
              id="username"
              type="text"
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              placeholder="Username"
              style={{
                borderColor: "#6ECBCB",
                backgroundColor: "rgb(249, 249, 249)",
              }}
            />
          </div>
          <div className="mb-6 p-2">
            <label
              className="block text-gray-700  font-bold mb-2 md:text-2xl"
              htmlFor="password"
            >
              Password
            </label>
            <input
              className="shadow appearance-none border rounded font-bold text-sm md:text-base w-full py-3 md:py-5 px-2 md:px-8 text-gray-700 mb-3 leading-tight focus:outline-none focus:shadow-outline hover:bg-gray-100"
              id="password"
              type="password"
              placeholder="Password"
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              style={{
                borderColor: "#6ECBCB",
                backgroundColor: "rgb(249, 249, 249)",
              }}
            />
          </div>
          <div className="flex justify-center ml-4">
            <button
              type="submit"
              className="bg-black text-white font-bold px-2 py-2 md:py-4 md:px-4 rounded focus:outline-none focus:shadow-outline text-xl"
            >
              Submit
            </button>
          </div>
        </form>
      </div>
    </>
  );
}

export default LoginPage;
