import { Link } from "react-router-dom";
import oops from "../../../assets/oops.webp";
import { ProductState } from "../../../context/Context";

const ProductNotFound = () => {
  const { state, dispatch } = ProductState();

  const clearFilter = () => {
    dispatch({ type: "CLEAR_FILTER" });
  };
  return (
    <>
      <div>
        <img src={oops} alt="" className="mx-auto w-32" />
        <p className="text-center p-4 text-lg">
          We couldn't find the product, please click
          <Link
            to="/"
            onClick={clearFilter}
            className="text-bannerText ml-2 mr-2"
          >
            here
          </Link>
          to return to home or clear filter.
        </p>
      </div>
    </>
  );
};
export default ProductNotFound;
