export const productReducer = (state, action) => {
  switch (action.type) {
    case "FILTER_BY_SEARCH":
      return { ...state, searchQuery: action.payload };
    case "CLEAR_FILTER":
      return { ...state, searchQuery: "" };
    case "USER_AUTHENTICATED":
      return { ...state, isAuthenticated: true };
    default:
      return state;
  }
};
