import { ProductState } from "../../../context/Context";

const SearchResult = () => {
  const {
    state: { searchQuery },
    dispatch,
  } = ProductState();
  return (
    <>
      {searchQuery && (
        <div className="flex flex-row bg-bgProject py-3 mb-2 px-4">
          <div className="fond-bold text-gray-700 md:text-xl">
            Your search result for {searchQuery}...
          </div>
          <div>
            <button
              className="px-2 py-1 text-headerText bg-navBackground font-bold md:text-xl"
              onClick={() => dispatch({ type: "CLEAR_FILTER" })}
            >
              Clear Filter
            </button>
          </div>
        </div>
      )}
    </>
  );
};
export default SearchResult;
