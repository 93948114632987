import { useRef, useState } from "react";

import { Link, useNavigate } from "react-router-dom";
import "./contact.css";

import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-google-places-autocomplete";
import axios from "axios";

const Contact = () => {
  const [address, setAddress] = useState("");
  const navigate = useNavigate();
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const [formData, setFormData] = useState({
    user_name: "",
    user_lname: "",
    business_name: "",
    email: "",
    phone: "",
    address: "",
    message: "",
    attachment: null,
    date: "",
  });

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  const handleImageChange = (event) => {
    const file = event.target.files[0];
    const allowedTypes = ["image/jpeg", "image/png", "image/gif", "image/webp"];

    if (file && allowedTypes.includes(file.type) && file.size <= 10000000) {
      setFormData({ ...formData, attachment: file });
    } else {
      setFormData({ ...formData, attachment: null });
      alert(
        "Invalid file type or size. Please select a valid image file (max size 10MB)."
      );
    }
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    // const createdDate = Date.now();
    // const formatDate = createdDate.toLocalString(); //
    const formPayload = new FormData();

    formPayload.append("user_name", formData.user_name);
    formPayload.append("user_lname", formData.user_lname);
    formPayload.append("business_name", formData.business_name);
    formPayload.append("email", formData.email);
    formPayload.append("phone", formData.phone);
    formPayload.append("address", formData.address);
    formPayload.append("message", formData.message);
    // formPayload.append("date", formatDate);
    formPayload.append("attachment", formData.attachment);
    if (
      formData.user_name &&
      formData.user_lname &&
      formData.phone &&
      formData.email &&
      formData.message
    ) {
      try {
        const response = await axios.post(
          "https://ayprintsign.onrender.com/contact/addContact",
          formPayload
        );

        setFormData({
          user_name: "",
          user_lname: "",
          business_name: "",
          email: "",
          phone: "",
          address: "",
          message: "",
          attachment: null,
          date: "",
        });

        setIsFormSubmitted(true);
        // navigate("/query");
      } catch (error) {
        // handle error
        console.error(error);
      }
    }
  };

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value.label);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
  };
  if (isFormSubmitted) {
    setTimeout(() => {
      setIsFormSubmitted(false);
    }, 2000);
  }
  const form = useRef();

  return (
    <>
      <div className="py-4 md:my-12 w-full md:w-10/12 mx-auto bg-bgProject rounded-xl">
        <h1 className="text-center text-2xl md:text-5xl text-iconColor pt-4">
          Contact us / Request a Quote!
        </h1>

        <span>
          {isFormSubmitted && (
            <div className="text-green-500 flex align-center  text-center max-w-md w-full rounded-lg shadow-lg m-4 p-12 mt-12 text-4xl mx-auto">
              Form submitted successfully!
            </div>
          )}
        </span>
        <div className="contact-box rounded-3xl p-0 md:p-9 w-full md:w-8/12 mx-auto ">
          <div className="right w-fit md:w-full mdForm">
            <form onSubmit={handleSubmit} ref={form}>
              <input
                type="text"
                className="field  w-96 md:w-full"
                placeholder="First Name*"
                name="user_name"
                value={formData.user_name}
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                className="field w-96 md:w-full"
                placeholder="Last Name*"
                name="user_lname"
                value={formData.user_lname}
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                className="field w-96 md:w-full"
                placeholder="Business Name"
                onChange={handleInputChange}
                name="business_name"
                value={formData.business_name}
              />
              <input
                type="email"
                className="field"
                placeholder="Email*"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                required
              />
              <input
                type="text"
                className="field"
                placeholder="Phone*"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                required
              />

              <label htmlFor="" className="text-xl font-light text-contactText">
                Address (Optional)
              </label>

              <PlacesAutocomplete
                value={address}
                onChange={(value) => setAddress(value)}
                onSelect={handleSelect}
              >
                {({
                  getInputProps,
                  suggestions,
                  getSuggestionItemProps,
                  loading,
                }) => (
                  <div>
                    <input
                      {...getInputProps({
                        placeholder: "demo address",
                        name: "address",
                        className:
                          "border-2 border-gray-200 rounded-md py-2 w-full",
                      })}
                      name="address"
                      onChange={handleInputChange}
                    />
                    <div className="autocomplete-dropdown-container">
                      {loading && <div>Loading...</div>}
                      {suggestions.map((suggestion) => {
                        const className = suggestion.active
                          ? "suggestion-item--active"
                          : "s// inline style for demonstration purpose";
                        const style = suggestion.active
                          ? { backgroundColor: "#fafafa", cursor: "pointer" }
                          : { backgroundColor: "#ffffff", cursor: "pointer" };
                        return (
                          <div
                            {...getSuggestionItemProps(suggestion, {
                              className,
                              style,
                            })}
                          >
                            <span>{suggestion.description}</span>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
              </PlacesAutocomplete>
              <textarea
                placeholder="Please place your query*"
                className="field mt-3"
                name="message"
                value={formData.message}
                onChange={handleInputChange}
                required
              ></textarea>
              <p className="py-4">Please attach your art work</p>
              <input
                type="file"
                placeholder="Phone"
                name="attachment"
                accept="image/*"
                className="mb-4"
                onChange={handleImageChange}
              />

              <button type="submit" className="btn">
                Send
              </button>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default Contact;
