import { useState, useEffect } from "react";
import axios from "axios";
import { ProductState } from "../../context/Context";
import { RiDeleteBin6Line } from "react-icons/ri";
import Loading from "../Loading";

function Query() {
  const [data, setData] = useState([]);
  const [updateCount, setUpdateCount] = useState(0);
  const {
    state: { isAuthenticated },
  } = ProductState();

  useEffect(() => {
    // fetch data from server

    async function fetchData() {
      try {
        const response = await axios.get(
          "https://ayprintsign.onrender.com/contact"
        ); //call the getBlogs function to fetch the blogs
        setData(response.data.data);

        //update the state with the fetched blogs
      } catch (error) {
        console.log(error);
      }
    }
    fetchData();
  }, [updateCount]);
  const handleDelete = async (id) => {
    try {
      const response = await axios.delete(
        `https://ayprintsign.onrender.com/contact/${id}`
      );

      setData(data.filter((item) => item.id !== id));
      setUpdateCount(updateCount + 1);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {data?.length >= 1 ? (
        <div className="flex flex-wrap justify-center px-12">
          {data.map((item) => (
            <div
              className="max-w-md w-full rounded-lg shadow-lg m-4"
              key={item.id}
            >
              <div className="bg-white rounded-lg overflow-hidden">
                <img
                  src={
                    item?.attachment &&
                    typeof item?.attachment === "object" && // check if image is in octet-stream format
                    item?.attachment.type === "application/octet-stream"
                      ? URL.createObjectURL(item?.attachment) // create blob URL from image data
                      : item?.attachment // otherwise use the image URL
                  }
                  alt=""
                  className="w-full object-cover shadow-2xl shadow-black"
                  style={{ maxHeight: "90%" }}
                />
                <div className="p-4">
                  <span className="flex flex-row">
                    <h3 className="text-base font-bold  text-gray-900">
                      Query made on :{" "}
                      {new Date(item?.createdAt).toLocaleString()}
                    </h3>
                    <div className="flex justify-end">
                      <RiDeleteBin6Line
                        className="text-red-500 cursor-pointer hover:text-red-600"
                        size={24}
                        onClick={() => handleDelete(item?._id)}
                      />
                    </div>
                  </span>
                  <h3 className="text-xl font-medium text-gray-900">
                    Business Name: {item.business_name}
                  </h3>
                  <p className="text-gray-600">
                    {item.user_name} {item.user_lname}
                  </p>
                  <p className="text-gray-600">Email: {item.email}</p>
                  <p className="text-gray-600">Phone: {item.phone}</p>
                  <p className="text-gray-600">{item.address}</p>
                  <p className="text-gray-600">Message: {item.message}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <Loading />
      )}
    </>
  );
}

export default Query;
