import header from "../../../assets/header.png";
const Advertisement = () => {
  return (
    <>
      <div className=" border-black bg-bgProject">
        <div className=" m-auto ">
          <img
            src={header}
            alt=""
            className="w-full h-full object-fill m-auto "
          />
        </div>
      </div>
    </>
  );
};
export default Advertisement;
