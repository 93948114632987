import { Link } from "react-router-dom";
import "./banner.css";

import business from "../../../assets/printingServices/business.webp";
import brochure from "../../../assets/printingServices/brochure.webp";
import flyer from "../../../assets/printingServices/flyer.webp";
import adjustable from "../../../assets/banners/adjustable.webp";
import mesh from "../../../assets/banners/mesh.webp";
import banner from "../../../assets/banner.webp";
import realState from "../../../assets/frames/realState.webp";
import signage from "../../../assets/signage/signage.webp";

const Banner = () => {
  // Store all slider images in an array
  const slider = [
    banner,
    business,
    brochure,
    flyer,
    adjustable,
    mesh,
    realState,
    signage,
  ];

  return (
    <>
      <section className=" bg-bgFooter py-6">
        <div className="slider">
          <div className="slide-track">
            {/* Map the slider array to render the images */}
            <div className="slide space-x-48">
              {slider.map((image, i) => (
                // Use the key prop to avoid the warning in the console
                <img
                  key={i}
                  className="w-max-full h-max-full"
                  src={image}
                  alt=""
                />
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default Banner;
