import CommonProducts from "../components/common/products/CommonProducts";
import ProdHeader from "../components/common/products/ProdHeader";

import Footer from "../components/footer/Footer";
import Header from "../components/Header";
import { ProductState } from "../context/Context";

const SignageRoute = () => {
  const {
    state: { sortedFinalProduct },
  } = ProductState();
  return (
    <>
      <Header />
      <ProdHeader />
      <CommonProducts
        product={sortedFinalProduct.filter(
          (product) => product.field === "signage"
        )}
      />
      <Footer />
    </>
  );
};
export default SignageRoute;
