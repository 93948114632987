import { useState } from "react";
import { Link } from "react-router-dom";
import ProductNotFound from "./ProductNotFound";

/**
 * A functional React component that displays a grid of common products, including an image, title, description, and link to request a quote.
 * @param {Object} props - The props passed to the component.
 * @param {Array<Object>} props.product - An array of objects representing each product to display, including an id, image, title, and text.
 * @returns {JSX.Element} - The rendered component.
 */
const CommonProducts = ({ product }) => {
  const lessMoreToggle = () => (
    <Link
      onClick={() => setReadMore(!readMore)}
      className="bg-bgProject text-gray-500 p-1"
    >
      {readMore ? "Read less.." : "Read more.."}
    </Link>
  );

  const [readMore, setReadMore] = useState(false);
  return (
    <>
      <div
        className={`grid grid-cols-1  md:grid-cols-2 gap-y-12  gap-x-32 md:mx-12 `}
      >
        {product.map((list, index) => (
          <div key={index} className="pb-6 px-6 md:px-auto">
            {/* Renders a Link to the individual product page, passing in the product id */}
            <Link to={`/product/${list.id}`}>
              <img
                src={list?.img}
                alt=""
                className="h-60 xl:h-80 w-full shadow-2xl shadow-black"
              />
            </Link>
            <div className={``}>
              {/* Renders a Link to the individual product page, passing in the product id */}
              <Link to={`/product/${list.id}`}>
                {/* Renders the product title */}
                <h2 className="font-extrabold text-2xl md:text-2xl xl:text-3xl pt-4 m-2">
                  {list.title}
                </h2>
              </Link>
              {/* Renders the product description */}
              <p className="text-sm md:text-base px-4">
                {list.text}

                {list?.text2 && !readMore && lessMoreToggle()}
              </p>
              <p className="px-4 pt-2 text-sm md:text-base">
                {readMore && list?.text2}{" "}
                {list?.text2 && readMore && lessMoreToggle()}
              </p>

              <p className="px-4 pt-2 text-sm ">
                {readMore && (
                  <p>
                    Place your order online by sending us an enquiry or call us
                    on
                    <a href="tel:+61401122107" className="font-bold m-1">
                      0401122107
                    </a>
                    . Please send us your enquiry and we will respond to you
                    within 24 hours
                  </p>
                )}
              </p>
            </div>
            {/* Renders a button that links to the contact page */}
            <button className="block mx-auto mt-6 ">
              <Link
                to="/contact"
                className="rounded-3xl px-6 py-2 bg-subText  hover:bg-bannerText text-headerText  font-bold text-lowercase"
              >
                Request Quote
              </Link>
            </button>
          </div>
        ))}
      </div>
      {/* Renders a ProductNotFound component if there are no products */}
      {!product || (!product.length >= 1 && <ProductNotFound />)}
    </>
  );
};

export default CommonProducts;
