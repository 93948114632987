import Footer from "../components/footer/Footer";
import UpdateGallery from "../components/gallery/UpdateGallery";
import Header from "../components/Header";
const GalleryRoute = () => {
  return (
    <>
      <Header />
      <UpdateGallery />
      <Footer />
    </>
  );
};
export default GalleryRoute;
