import logo from "../../assets/logo.webp";
import { Link } from "react-router-dom";

import { BsFillTelephoneFill } from "react-icons/bs";
import { SlLocationPin } from "react-icons/sl";
import { MdEmail } from "react-icons/md";
import { productions, socialIcons } from "../data/Data";

const Footer = () => {
  const infoStyles = `text-headerText hover:text-bannerText xxl:text-2xl lg:text-xl`;
  const iconStyles = "w-10  mt-2 px-1 md:px-auto  mx-2 text-xl md:text-3xl";
  return (
    <>
      <footer className="flex flex-col md:flex-row justify-around space-x-24 pt-2 bg-navBackground text-headerText px-auto xxl:px-8">
        <div className="">
          {/* location and contacts details */}
          <div className="flex sm:flex-row flex-col mx-4 md:mx-0 sm:space-x-12  md:space-x-2 lg:space-x-28 xlm:space-x-64 xxl:space-x-96 xl:ml-12 ">
            <div>
              <span className="text-4xl text-iconColor">
                <Link to="/">
                  <img
                    src={logo}
                    alt=""
                    className="w-full xxl:w-full rounded-full px-2 border-8 border-black"
                  />
                </Link>
              </span>
            </div>

            <div className="mt-auto md:mt-14">
              <div>
                <h1 className="text-bannerText  md:text-2xl mb-3 hidden xl:block">
                  Get in Touch
                </h1>
                <p className="">
                  <span className="font-bold text-xl text-subText">
                    AY Print & Sign
                  </span>
                  <div className="flex flex-row w-fit space-x-0">
                    <span className="mr-3 lg:text-xl xxl:text-2xl">
                      <SlLocationPin />
                    </span>
                    <p className=" lg:text-xl xxl:text-2xl ">Canberra, ACT</p>
                  </div>
                  <div className="flex flex-row w-fit space-x-5">
                    <span className="xxl:text-2xl lg:text-xl">
                      <BsFillTelephoneFill />
                    </span>
                    <span className="xxl:text-2xl lg:text-xl">
                      <a
                        href="tel:+61401122107"
                        className="text-headerText -ml-2 lg:text-xl xxl:text-2xl "
                      >
                        0401122107
                      </a>
                    </span>
                  </div>
                  <div className="flex flex-row w-fit space-x-2">
                    <span>
                      <MdEmail className="mr-2 xxl:text-2xl lg:text-xl" />
                    </span>
                    <span>
                      <a
                        href="mailto:sales@ayprintandsign.com.au"
                        className="text-headerText lowercase text-sm md:text-base xxl:text-2xl lg:text-xl"
                      >
                        sales@ayprintandsign.com.au
                      </a>
                    </span>
                  </div>
                  {/* Social media Links */}
                  <span className="block">
                    <div className="socials text-headerText flex flex-row  justify-center space-x-0 w-fit  pr-8">
                      {socialIcons.map((icons, index) => (
                        <a
                          key={index}
                          target="_blank"
                          rel="noreferrer"
                          href={icons.href}
                          className={`${icons.color}  ${iconStyles} ${icons?.size}`}
                        >
                          <img
                            src={icons.icon}
                            alt=""
                            className=" rounded-full border-1 border-black "
                          />
                        </a>
                      ))}
                    </div>
                  </span>
                </p>
              </div>
            </div>
          </div>

          {/* end of first 2 div */}
        </div>

        <div className="flex flex-row md:px-auto space-x-12 sm:space-x-40 md:space-x-12 lm:space-x-40 lg:space-x-40 xlm:space-x-80 xxl:space-x-96  mt-4 md:mt-3 pr-12 md:pr-1 lg:pr-2 xxl:pr-32">
          {/* information */}
          <div className=" -ml-16 md:-ml-auto sm:-mt-0 text-center">
            <span className="information text-left ">
              <h2 className=" md:text-xl lg:text-2xl text-bannerText xxl:text-3xl ">
                Information
              </h2>
            </span>

            <ul className="space-y-2 text-xs sm:text-base spa">
              <li>
                <Link className={infoStyles} to="/about">
                  About Us
                </Link>
              </li>
              <li>
                <Link className={infoStyles} to="/product">
                  Products
                </Link>
              </li>
              <li>
                <Link className={infoStyles} to="/contact">
                  Contacts
                </Link>
              </li>
            </ul>
          </div>

          {/* Shop Products */}

          <div className="shopProducts mt-8  sm:mt-10 xl:pr-4 ">
            <h2 className="text-bannerText md:text-xl lg:text-2xl xxl:text-3xl">
              Shop Products
            </h2>
            <div className="">
              <ul className="space-y-1 text-xs sm:text-base text-center">
                {productions.map((product, index) => (
                  <li key={index}>
                    <Link to={`${product.path}`} className={infoStyles}>
                      {product.title}
                    </Link>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </footer>
      <div className="bg-navBackground text-headerText text-center p-4">
        <hr className="mx-auto  color-headerText mb-2" />

        <div className="font-bold p-4 w-full bg-navBackground">
          Copyright{" "}
          <Link to="/login" className="text-headerText">
            ©
          </Link>{" "}
          2023 All rights reserved | AY print & sign
        </div>
      </div>
    </>
  );
};
export default Footer;
