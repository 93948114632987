import React from "react";
import { BiLoaderAlt } from "react-icons/bi";

const Loading = () => {
  return (
    <div className="flex flex-col items-center justify-center space-y-4 mb-6">
      <BiLoaderAlt className="text-bannerText text-5xl animate-spin mr-2 h-12 w-12" />
      <span className="text-lg font-semibold text-gray-700">
        Loading content...
      </span>
    </div>
  );
};

export default Loading;
