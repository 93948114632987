import { Link } from "react-router-dom";
import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineClose } from "react-icons/ai";
import { nav } from "../components/data/Data";
import { useState } from "react";
import logo from "../assets/logo.webp";
import Search from "./common/search/Search";
import { AiOutlineSearch } from "react-icons/ai";

import ProductHover from "./common/products/ProductHover";
import ContactHeader from "./contact/ContactHeader";

const Header = () => {
  // Setting useState for toggle button
  const [open, setOpen] = useState(true); // state to keep track of menu open or close
  const [search, setSearch] = useState(false); // state to keep track of search bar open or close
  const [showProducts, setShowProducts] = useState(false); // state to keep track of whether to show product hover or not
  const toggleMenu = () => {
    setOpen(!open);
  };
  const productHover = () => {
    setShowProducts(!showProducts);
  };

  return (
    <div
      className="z-40 h-124"
      style={{ position: "fixed", top: 0, width: "100%" }}
    >
      <ContactHeader />
      {/* Include ContactHeader component */}
      <header className="bg-navBackground pt-2">
        <div className="px-4">
          <div>
            <button
              className="inline-block md:hidden text-headerText py-6 text-3xl sm:text-4xl ml-4 "
              onClick={toggleMenu}
            >
              {/* Setting menu icon on basis of useState  */}
              {open ? <GiHamburgerMenu /> : <AiOutlineClose />}
            </button>
          </div>

          <div className="flex flex-col md:flex-row ">
            <div className="-mt-20 md:mt-auto flex flex-row ">
              <Link to="/">
                <img
                  src={logo}
                  alt=""
                  className="w-48 rounded-full  border-8 border-black"
                />
              </Link>

              <AiOutlineSearch
                className="text-headerText text-5xl block md:hidden absolute right-4 -mt-5"
                onClick={() => setSearch(!search)}
              />
            </div>

            <span
              className={`${search ? "block md:hidden" : "hidden"} md:"hidden"`}
            >
              <Search />
            </span>

            <div>
              <nav
                // Conditional toggle option of hamburger menu
                className={` 
              sm:${open ? "hidden" : "block"}
             
                flex md:flex-row flex-col ${
                  !open ? "space-y-8 pb-4" : "space-y-0"
                }
               md:space-x-6  xl:space-x-12`}
              >
                {/* We are fetching the list from Data.s js, using simple map function to return all list  */}
                {nav.map((list, index) => (
                  <div key={index} className={` text-headerText text-m pr-6`}>
                    <Link
                      to={list.path}
                      className={` text-bannerText lg:text-xl hover:text-subText md:block  ${
                        list?.blocks
                      } ${open ? "hidden" : "block"}`}
                      onMouseEnter={
                        list.text === "PRODUCT" && (() => productHover())
                      }
                    >
                      {list.text}
                    </Link>
                  </div>
                ))}
              </nav>
            </div>
            <span className={`${search ? "hidden md:block" : "hidden"}`}>
              <Search />
            </span>
            <AiOutlineSearch
              className="text-headerText text-5xl  hidden md:block -mt-2 lg:mr-3"
              onClick={() => setSearch(!search)}
            />
          </div>
        </div>
      </header>
      {showProducts && <ProductHover />}
    </div>
  );
};
export default Header;
// In this code, we have added a search icon that is displayed on the header component. When this icon is clicked, it toggles the search bar.

// The icon component used here is AiOutlineSearch which is imported from react-icons/ai package.

// The onClick event is attached to the icon that toggles the search bar when clicked. We have used the setSearch hook provided by useState to toggle the search bar.

// Finally, we close the header element and render the ProductHover component conditionally using the showProducts state.

// This completes the code for the Header component.
