import React, { useState } from "react";
import axios from "axios";

const BlogForm = () => {
  const [formData, setFormData] = useState({
    title: "",
    tags: "",
    description: "",
    testimonial: "",
    reviewer: "",
    images: [],
    review: null,
  });

  const handleTitleChange = (event) => {
    setFormData({ ...formData, title: event.target.value });
  };

  const handleTagsChange = (event) => {
    setFormData({ ...formData, tags: event.target.value });
  };

  const handleDescriptionChange = (event) => {
    setFormData({ ...formData, description: event.target.value });
  };

  const handleTestimonialChange = (event) => {
    setFormData({ ...formData, testimonial: event.target.value });
  };

  const handleReviewerChange = (event) => {
    setFormData({ ...formData, reviewer: event.target.value });
  };

  const handleImageChange = (event) => {
    const files = event.target.files;
    const allowedTypes = ["image/jpeg", "image/png"];
    const allowedSize = 10000000; // 10MB

    // filter out invalid files
    const validFiles = Array.from(files).filter(
      (file) => allowedTypes.includes(file.type) && file.size <= allowedSize
    );

    if (validFiles.length > 0) {
      setFormData({ ...formData, images: validFiles });
    } else {
      setFormData({ ...formData, images: [] });
      alert(
        "Invalid file type or size. Please select a valid image file (max size 10MB)."
      );
    }
  };

  const handleReviewChange = (event) => {
    setFormData({ ...formData, review: event.target.value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault(); // prevent default form submission behavior

    const formPayload = new FormData(); // create a new FormData object to hold the form data

    // add each field to the FormData object
    formPayload.append("title", formData.title);
    formPayload.append("tags", formData.tags);
    formPayload.append("description", formData.description);
    formPayload.append("testimonial", formData.testimonial);
    formPayload.append("reviewer", formData.reviewer);

    formData.images.forEach((image) => {
      formPayload.append("images", image);
    });

    formPayload.append("review", formData.review);

    // send an HTTP POST request to the server with the form data

    try {
      const response = await axios.post(
        "http://localhost:8080/blogs/addBlog",
        formPayload
      );
      setFormData({
        title: "",
        tags: "",
        description: "",
        testimonial: "",
        reviewer: "",
        images: [],
        review: null,
      });
      // handle success
    } catch (error) {
      // handle error
      console.error(error);
    }
  };

  const inputStyles =
    "rounded-md border-gray-300 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 py-2 px-3 w-8/12 ";
  const textAreaStyles =
    "rounded-md border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 py-2 px-3 w-6/12 resize-none";
  const labelText = "block text-gray-700 font-bold mb-2";
  const options = Array.from({ length: 5 }, (_, i) => i + 1);
  return (
    <>
      <h1 className="text-xl md:text-2xl xl:text-4xl pt-6 p-4 font-bold text-subText">
        Please add your recent works in blogs
      </h1>
      <form
        onSubmit={handleSubmit}
        className="space-y-4 p-4 pl-8 bg-gray-100 border border-gray-200 rounded-md shadow-md transition duration-150 w-10/12 mx-6"
      >
        <div>
          <label htmlFor="title" className={labelText}>
            Title:
          </label>
          <input
            type="text"
            id="title"
            value={formData.title}
            onChange={handleTitleChange}
            required
            className={inputStyles}
          />
        </div>
        <div>
          <label className={labelText} htmlFor="tags">
            Tags:
          </label>
          <input
            type="text"
            id="tags"
            value={formData.tags}
            onChange={handleTagsChange}
            required
            className={inputStyles}
          />
        </div>
        <div>
          <label className={labelText} htmlFor="description">
            Description:
          </label>
          <textarea
            id="description"
            value={formData.description}
            onChange={handleDescriptionChange}
            required
            className={textAreaStyles}
          />
        </div>
        <div>
          <label className={labelText} htmlFor="testimonial">
            Testimonial:
          </label>
          <textarea
            id="testimonial"
            value={formData.testimonial}
            onChange={handleTestimonialChange}
            className={textAreaStyles}
          />
        </div>
        <div>
          <label className={labelText} htmlFor="reviewer">
            Reviewer:
          </label>
          <input
            type="text"
            id="reviewer"
            value={formData.reviewer}
            onChange={handleReviewerChange}
            className={`rounded-md border-gray-300 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 py-2 px-3 w-6/12`}
          />
        </div>
        <div>
          <label className={labelText} htmlFor="review">
            Review Stars:
          </label>
          <select
            id="review"
            value={formData.review}
            onChange={handleReviewChange}
            className="w-32 py-2"
          >
            {options.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className={labelText} htmlFor="image">
            Image:jpg/png only
          </label>
          <input
            type="file"
            id="image"
            name="images"
            multiple={true}
            accept="image/*"
            className={`rounded-md border-gray-300 hover:border-blue-500 focus:outline-none focus:ring-2 focus:ring-blue-500 py-2 px-3 w-6/12`}
            onChange={handleImageChange}
          />
        </div>

        <button
          type="submit"
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded transition duration-300 ease-in-out transform hover:-translate-y-1 hover:scale-110"
        >
          Submit
        </button>
      </form>
    </>
  );
};

export default BlogForm;
