import ProductHeaders from "./ProductHeaders";
import { products } from "../../data/Data";
import { Navigate, Link } from "react-router-dom";
import { useState } from "react";
import { ProductState } from "../../../context/Context";
import ProductNotFound from "./ProductNotFound";
import { productions } from "../../data/Data";

const Products = () => {
  const {
    state: { sortedProduct },
    dispatch,
  } = ProductState();

  return (
    <>
      <section className=" mb-20">
        <div
          className={`grid grid-cols-1  md:grid-cols-2 gap-y-12  gap-x-32 md:mx-12 `}
        >
          {sortedProduct.map((list, index) => (
            // We are conditional returning only 8 of our products, depending on index
            // if (index <= 7) {
            <div key={index} className="pb-6 px-6 md:px-auto">
              <Link to={`${list.path}`}>
                <img
                  src={list?.img}
                  alt=""
                  className="h-60 xl:h-80 w-full shadow-2xl shadow-black"
                />
              </Link>
              <div className={``}>
                <Link to={`${list.path}`}>
                  <h2 className="font-extrabold text-2xl md:text-2xl xl:text-3xl pt-4 m-2 hover:text-bannerText">
                    {list.title}
                  </h2>
                </Link>
                <p className="text-sm md:text-md px-4 xl:text-xl">
                  {list.text}
                </p>
              </div>
              <button className="block mx-auto mt-6 ">
                <Link
                  to="/contact"
                  className="rounded-3xl px-6 py-2 bg-subText  hover:bg-bannerText text-headerText  font-bold text-lowercase"
                >
                  Request Quote
                </Link>
              </button>
            </div>
          ))}
        </div>
        {!sortedProduct || (!sortedProduct.length >= 1 && <ProductNotFound />)}
      </section>
    </>
  );
};
export default Products;
