import { BsFillTelephoneFill } from "react-icons/bs";
import { Link } from "react-router-dom";

const ContactHeader = () => {
  return (
    <>
      <div className="flex flex-row p-4 md:px-20 bg-subText py-5">
        <div className=" md:mb-auto">
          <a
            href="tel:+61401122107"
            className="flex flex-row font-bold md:text-2xl"
          >
            <BsFillTelephoneFill className="mx-4" />
            0401122107
          </a>
        </div>
        <div>
          <button>
            <Link
              to="/contact"
              className="rounded-xl text-headerText px-4 py-3 md:text-xl bg-navBackground"
            >
              Request quote
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};
export default ContactHeader;
