import { useState } from "react";
import { Nav } from "react-bootstrap";
import { AiOutlineSearch } from "react-icons/ai";
import { Link, Navigate } from "react-router-dom";
import { ProductState } from "../../../context/Context";

const Search = ({ style, length, color }) => {
  const [width, setWidth] = useState(false);
  const {
    state: { searchQuery },
    dispatch,
  } = ProductState();

  return (
    <>
      <form
        action=""
        className={`flex flex-row ${style}`}
        onSubmit={(e) => e.preventDefault()}
      >
        <input
          type="text"
          placeholder="Search ..."
          // className=" m-2 px-3 py-2 w-5/6"
          className={`rounded-md py-2 px-2 border-solid border-2 border-${color} mb-4 md:mb-auto pr-12 ${length}`}
          onChange={(e) =>
            dispatch({ type: "FILTER_BY_SEARCH", payload: e.target.value })
          }
          value={searchQuery}
        />
      </form>
    </>
  );
};
export default Search;
