import Banner from "../components/common/banner/Banner";
import ProductHeaders from "../components/common/products/ProductHeaders";
import Products from "../components/common/products/Products";
import SearchResult from "../components/common/search/SearchResult";

import ContactLink from "../components/contact/ContactLink";
import Footer from "../components/footer/Footer";
import Header from "../components/Header";
import Advertisement from "../components/common/products/Advertisement";
import Gallery from "../components/gallery/Gallery";

const Home = () => {
  return (
    <>
      <Header />
      <Advertisement />
      <Banner />
      <ProductHeaders />
      <SearchResult />
      <Products />
      <Gallery />

      <ContactLink />

      <Footer />
    </>
  );
};
export default Home;
